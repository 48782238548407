import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import _Link from './componant/_Link';
import Home from './Home';
import './App.css'
function App()
 {
  return (
    <>
    <div>
    <_Link />
    </div>
      
           

           
     </>
  );
}

export default App;
