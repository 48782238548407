import React from "react";
import {FiFacebook} from 'react-icons/fi'
import {AiFillFacebook,AiFillGithub,AiFillInstagram,AiFillTwitterCircle,} from 'react-icons/ai'
import {ImGooglePlus2} from 'react-icons/im'
//import "./Navbar1.css";
import './Footer.css';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from "react-router-dom";
export default function Footer1() {
  return (
    <>
    <footer className="footer">
    <Container fluid className="cont-1 mt-4" >
      <Row className="mt-4">
        <Col md={3} sm={6}>
        <div className="widget-title d-flex justify-content-md-center">
          <h3 className="mt-4  py-md-4 fw-bolder fs-3 text-white">About US</h3>
        </div>
          <p className=" titl d-flex justify-content-sm-center ml-4">Integer rutrum ligula eu dignissim laoreet.<br/> Pellentesque venenatis nibh sed tellus faucibus<br></br> bibendum. Sed fermentum est vitae rhoncus molestie. <br/> Cum sociis natoque penatibus et magnis dis montes.</p>
          <i className="fa fa-facebook"></i>
       </Col>
        <Col md={3} sm={6} >
        <div className="widget-title d-flex justify-content-md-center">
          <h3 className="mt-sm-4  py-sm-4 fw-bolder fs-3 text-white">Information Link</h3>
        </div>
        <div className="nav-link d-flex justify-content-md-center ml-4">
         <ul className=" navbar-nav d-flex justify-content-md-center ml-4">
          <li className="nav-item">
                <NavLink to="#" className='link'>
                  home
                </NavLink>
           </li>
           <li className="nav-item">
                <NavLink to="#" className='link'>
                   about us
                </NavLink>
           </li>
              <li className="nav-item">
                <NavLink to="#" className='link'>
                  pricing
                </NavLink>
               </li>
               <li className="nav-item">
                <NavLink to="#" className='link'>
                services
                </NavLink>
               </li>
               <li className="nav-item">
                <NavLink to="#" className='link'>
                contact us
                </NavLink>
               </li>
               </ul>
            </div>
        </Col>
        <Col md={3} sm={6} >
        <div className="widget-title d-flex justify-content-md-center">
          <h3 className="mt-md-4  py-md-4 fw-bolder fs-3 text-white">Contact Details</h3>
        </div>
        <div className="nav-link d-flex justify-content-sm-center ml-4">
           <ul className=" navbar-nav ">
             <li className="nav-item">
                <NavLink to="#" className='link'>
                info@thisside.com
                </NavLink>
             </li>
             <li className="nav-item">
                <NavLink to="#" className='link'>
                www.yourside.com
                </NavLink>
             </li>
              <li className="nav-item">
                <NavLink to="#" className='link'>
                www.fecbook.com
                </NavLink>
               </li>
               <li className="nav-item">
                <NavLink to="#" className='link'>
                India
                </NavLink>
               </li>
               <li className="nav-item">
                <NavLink to="#" className='link'>
                +91-9999999999
                </NavLink>
               </li>
               </ul>
            </div>
        </Col>
        <Col md={3} sm={6} >
        <div className="widget-title d-flex justify-content-md-center">
          <h3 className="mt-md-4  pt-4 fw-bolder fs-3 text-white">Social Media</h3>
        </div>
          <div className="d-flex justify-content-md-center ml-4">
           <NavLink to='#' className="Social_media"><AiFillFacebook/></NavLink>
           <NavLink to='#' className="Social_media"><AiFillTwitterCircle/></NavLink>
           <NavLink to='#' className="Social_media"><ImGooglePlus2 /></NavLink>
           <NavLink to='#' className="Social_media"><AiFillInstagram/></NavLink>
           <NavLink to='#' className="Social_media"><AiFillGithub /></NavLink>
          </div>
          <div className="d-flex justify-content-md-center">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3551.161691779055!2d84.58883512695311!3d27.119716699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39949f56d3ccb7f7%3A0xfcec741c5d563542!2sSakshi%20Singar%20Store!5e0!3m2!1sen!2sin!4v1672070425144!5m2!1sen!2sin" width="150" height="150" loading="lazy"></iframe>
          </div>
        </Col>
        </Row>
       
    </Container>
    </footer>  
    <div className="footer2">
        <p>All Rights Reserved. © 2018 </p>
       </div>
    </>
  );
}
