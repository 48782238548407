import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from "react-router-dom";
import  './_Header.css';
import { Image } from 'react-bootstrap';
import { GoSearch} from "react-icons/go";
function Header() {   

  return (
    <Navbar fixed="top" expand="lg" className="header header-fluid">
      <Container fluid>
      <img
      src='image/logo.png' 
      />
        <Navbar.Toggle aria-controls="navbarScroll"/>
        <Navbar.Collapse id="navbarScroll" className=''>
          <Nav className="ms-auto my-md-10 mx-4 my-lg-0 nav " style={{ maxHeight: '500px'}}>
            <NavLink  to="/home" className="item px-md-2"> Home
            </NavLink>
            <NavLink to="/about" className="item px-md-2"> about</NavLink>
            <NavLink to="/gallery" className="item px-md-2"> Gallery</NavLink>
            <NavLink to="/#" className="item px-md-2"> </NavLink>
            <div className=" item dropdown px-md-2">
           <span className=" dropbtn">course</span>
               <div className="dropdown-content">
               <NavLink to='/Course' className="a">BBA</NavLink>
               <NavLink to='/Course' className="a">BCA</NavLink>
               <NavLink to='/Course' className="a">MCA</NavLink>
                 </div>
             </div>
             <div className=" item dropdown px-md-2">           <span className=" dropbtn">blog</span>
               <div className="dropdown-content">
               <NavLink to='/Course' className="a">BBA</NavLink>
               <NavLink to='/Course' className="a">BCA</NavLink>
               <NavLink to='/Course' className="a">MCA</NavLink>
                 </div>
             </div>
            
          {/*
            <NavDropdown title="course" id="navbarScrollingDropdown"  className="item " >
              <NavDropdown.Item className='drop-item'> <NavLink to='/Course' className="link-item ">BBA</NavLink></NavDropdown.Item>
               <NavDropdown.Item className='drop-item'><NavLink to='/Course' className="link-item ">BCA</NavLink> </NavDropdown.Item>
               <NavDropdown.Item className='drop-item'><NavLink to='/Course' className="link-item ">MCA</NavLink></NavDropdown.Item>
            </NavDropdown>
          
            <NavDropdown title="blog" id="navbarScrollingDropdown"className="item ">
              <NavDropdown.Item className='drop-item'>course</NavDropdown.Item>
              <NavDropdown.Item className='drop-item' >
                Another action
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className='drop-item'>
                Something else here
              </NavDropdown.Item>
            </NavDropdown>  */}
            <NavLink  to="/teacher" className="item px-md-2">  teachers </NavLink>
            <NavLink to="/contect" className="item px-md-2"> contect </NavLink>
          </Nav>
         {/* <button className='btn'>Join Now</button>*/}
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success"> <GoSearch /></Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;