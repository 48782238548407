import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './_Header';
import './App.css'
import _Carousel from './Carusel';
import Grid from './Grid';
import Teacher from './Teacher';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './componant/About';
import Gallery from './submenu/Gallery';
import Footer1 from './Footer';
export default function Home() {
  return (
    <div>
        <div>
             <Header />
            </div>
            <div>
              <br /><br /><br />
            </div>
            <div className=''>
             <_Carousel/>
            </div>
            <div className='contant container-fluid my-4'>
              <h1 >
                about
              </h1>
                <p>Lorem Ipsum dolroin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem <br/> quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem!</p>
            </div>
            <div>
              <Grid />
            </div>
               <div className='teacher'>
                  <div className='contant container-fluid '>
                     <h1 >
                           our teachers
                     </h1>
                      <p id='teacher' className=''> A teacher is a person who plays a pivotal role in molding a student's life. Some teachers remain in your memory as a key to a  <br />few life problems.  A teacher imparts not only academic knowledge but  <br />also shares ethical values, and imbibes morality that shapes our personality as a better human being.</p>
                       <div> <Teacher /></div>   
                  </div>
                  <Footer1/> 
              </div> 
                 
              <div>
               
                </div>
           
    </div>
  )
}
