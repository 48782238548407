import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import  './Carousel.css';
function _Carousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="image/slider-01.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
        <h1>
          <label id='lab '>VPS Servers </label>
          Company
          </h1>
          <p>1 IP included with each server Your Choice of any OS (CentOS, Windows, Debian, Fedora) FREE Reboots</p>
          <div className='bt ' id='bt1'>
              <div className=''>
               <button className='btn'>CONTECT US</button>
               </div>
               <div>
               <button className='btn'>READ MORE</button>
             </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="image/slider-02.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
        <h1>
          
           SmartEDU
              <label id='lab'>Education School <b/></label>
          </h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <div className='bt' id='bt2'>
               <div>
               <button className='btn '>CONTECT US </button>
               </div>
               <div>
               <button className='btn'>READ MORE</button>
               </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="image/slider-03.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h1>
          <label id='lab'>SmartEDU  <b/></label>
              Education College
          </h1>
          <p>
          With Landigoo responsive landing page template, you can promote your all hosting, domain and email services.
          </p>
          <div className='bt' id='bt3'>
               <div>
               <button className='btn'>CONTECT US</button>
               </div>
               <div>
               <button className='btn'>READ MORE</button>
               </div>
          </div>
          
        </Carousel.Caption>
      </Carousel.Item>
     
    </Carousel>
  );
}

export default _Carousel;