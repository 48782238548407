export interface Iteacher {
    src: string
    name: string
    teach: string
}
export const TeacherDetails: Iteacher[]=
  [
    {src:'image/team-01.png',name:'miss anjali',teach:'math teacher'},
    {src:'image/team-02.png',name:'miss sweta',teach:'drowing teacher'},
    {src:'image/team-03.png',name:'mr john',teach:'science teacher'},
    {src:'image/team-04.png',name:'miss siya',teach:'danceing teacher'},
    {src:'image/team-03.png',name:'mr john',teach:'science teacher'},
    {src:'image/team-04.png',name:'miss siya',teach:'danceing teacher'},
    {src:'image/team-03.png',name:'mr john',teach:'science teacher'},
    {src:'image/team-04.png',name:'miss siya',teach:'danceing teacher'}
 ];
 
export const Gallery_: Iteacher[]=
[
  {src:'image/pic1.jpeg',name:'event',teach:'math teacher'},
    {src:'image/pic2.jpeg',name:'miss sweta',teach:'drowing teacher'},
    {src:'image/pic3.jpeg',name:'mr john',teach:'science teacher'},
    {src:'image/pic4.jpeg',name:'miss siya',teach:'danceing teacher'},
    {src:'image/pic4.jpeg',name:'mr john',teach:'science teacher'},
    {src:'image/pic5.jpeg',name:'miss siya',teach:'danceing teacher'},
    {src:'image/pic6.jpeg',name:'mr john',teach:'science teacher'},
    {src:'image/pic7.jpeg',name:'miss siya',teach:'danceing teacher'},
    {src:'image/pic22.jpeg',name:'miss siya',teach:'danceing teacher'},
    {src:'image/pic24.jpeg',name:'miss siya',teach:'danceing teacher'},
    {src:'image/pic25.jpeg',name:'miss siya',teach:'danceing teacher'},
    {src:'image/pic.jpeg',name:'miss siya',teach:'danceing teacher'}
];