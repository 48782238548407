import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.css';
import './Grid.css';
function Grid() {
  return (
    <Container>
      <Row className='mx-md-2 my-4 grid align-left'>
        <Col md={6} className="jumbotron">
            <h4 className='heading'>2018 BEST SMARTEDU EDUCATION SCHOOL </h4>
            <h1 id='head'>Welcome to SmartEDU education school</h1>
            <p id='head1'>Quisque eget nisl id nulla sagittis auctor quis id. Aliquam quis vehicula enim, non aliquam risus. Sed a tellus quis mi rhoncus dignissim.<br/> <br />Integer rutrum ligula eu dignissim laoreet. Pellentesque venenatis nibh sed tellus faucibus bibendum. Sed fermentum est vitae rhoncus molestie. Cum sociis natoque penatibus et magnis montes, nascetur ridiculus mus. Sed vitae rutrum neque. </p>
              <button className='btn my-2' id='botom'>learn more</button>          
        </Col>
        <Col md={6}>
            <img src="image/img-02.jpg" alt=""className='img-fluid'/>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
        <img src="image/blog_6.jpg" alt=""className='img-fluid'/>
        </Col>
        <Col md={6}>
            <h1 id='head'>The standard Lorem Ipsum <br></br> passage, used since the 1500s</h1>
            <p id='head1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
              <button className='btn ' id='botom'>learn more</button>    
        </Col>
      </Row>
    </Container>
  );
}

export default Grid;