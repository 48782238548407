import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {TbZoomInArea} from "react-icons/tb";
import Header from '../_Header';
import './Gallery.css';
import {AiOutlineCloseSquare} from 'react-icons/ai'
import { Col, Row } from 'react-bootstrap';
import { Iteacher, Gallery_ } from '../TeacherData';
import { useState } from 'react';
import Footer from '../Footer';
export default function Gallery() 
{
  const [data,setdata]=useState(Gallery_);
  const [model,setmodel]=useState(false);
  const [count,setcount]=useState(1);
  const [cheak,setcheak]=useState(false);
  const [tempsrc,setTempsrc]=useState("");
  
 // cheak? setcount(count+1) : setcount(count-1)
 const Zoomin=(n:any)=>
 {
   alert(count)
 }
 const Zoomout=()=>
 {
  alert(count)
 }
  const getImg = (src:any)=>{
   setTempsrc(src);
   setmodel(true);
    
  }
 
 {

 }
  return (
    <>
        <div>src
         <Header /> 
       </div>
       <div className='main' id='main'>
            <div className="all-title-box">
		          <div className="container text-center">
		             <h1 className='head'>Gallery </h1>
                  <div><p className="m_1">Lorem Ipsum Dolroin Gravida Nibh Vel Velit..</p></div> 
               </div>
            </div>
        
           <div className='text-center py-4'>
               <h3 className='my-4'>Need Help? Sure we are Online!</h3>
              <p id='dis'>Let us give you more details about the special offer website you want us. Please fill out the form below. <br />
                 We have million of website owners who happy to work with us!</p>
            </div>
            </div> 
            <div className={model? " model open" : "model"}>
              <img src={tempsrc} alt="" className='imgs'/>
             <div className="svg">
             <AiOutlineCloseSquare className='sv'onClick={()=>setmodel(false)} />
             <TbZoomInArea className='sv' onClick={()=>Zoomin}/>
              <TbZoomInArea className='sv' onClick={()=>Zoomout}/>
              </div>
            </div>
            <h4 className='mr-4 text-center py-4 head'>Gallery</h4>
           <div className="gallery">
            { data.map((item:Iteacher, index)=>
            {
              return(
                <div className="pics" key={index} onClick={() => getImg(item.src)}>
                   <img src={item.src} alt="" style={{width:'100%'}}/>
                </div>

              )
            }
            )
            }
           </div>
           <div>
            <Footer />
           </div>
</> 
  )
  }


