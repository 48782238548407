import React,{useState} from 'react'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './Teacher.css';
import Image from 'react-bootstrap/Image'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Iteacher, TeacherDetails } from './TeacherData';

export default function Teacher() 
{
     
       const [Teams,setTeams]=useState( TeacherDetails)
  return (
   <>
      <div className='wrapper container-fluid'>
      {
      Teams.map((Team: Iteacher )=>  {
        return (        
         <div className="container">
           <img src={Team.src} alt="Avatar" className=" image"/> 
           <div className="overlay">
              <p className='title'> {Team.name}</p> 
           </div>
           <p className='teach'>{Team.teach}</p>
          </div>
        )
        
        })
     }
          
        </div>
        
   </>
  )
}
