import React from 'react'
import { Routes, Route,Link } from "react-router-dom";
import App from '../App';
import Grid from '../Grid';
import Home from '../Home';
import { Component} from 'react';
import { Carousel } from 'react-bootstrap';
import Carusel from '../Carusel';
import Contect from '../submenu/Contect';
import Gallery from '../submenu/Gallery';
export default function _Link() {
  return (
    <div>
        <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/grid" element={<Grid/>}/>
        <Route path="/about" element={<Carusel/>}/>
        <Route path="/contect" element={<Contect/>}/>
        <Route path="/gallery" element={<Gallery/>}/>
         
      </Routes>
        
    </div>
  )
}
