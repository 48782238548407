import { format } from 'path'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../Footer'
import Header from '../_Header'
import './Contect.css'
import { GoLocation } from "react-icons/go";
import { CgMail } from "react-icons/cg";
import { FaYoutube } from "react-icons/fa";
import { BiSend } from "react-icons/bi";
export default function Contect() {
  function form()
  {
   alert("you want submited");
  }
  return (
   <>
    <div>
      <Header></Header>
    </div>
<div className='main'>
  <div className="all-title-box">
		<div className="container text-center">
			<h1 className='head'>Contact </h1>
            <div><p className="m_1">Lorem Ipsum Dolroin Gravida Nibh Vel Velit..</p></div>
		
    </div>
	</div>
    <div className='text-center py-4'>
        <h3 className='my-4'>Need Help? Sure we are Online!</h3>
        <p id='dis'>Let us give you more details about the special offer website you want us. Please fill out the form below. <br />
         We have million of website owners who happy to work with us!</p>
      </div>
    </div>
    <Container className='box'>
      <Row>
      <div className="col-xl-4 col-md-12 col-sm-12 my-4">
                    <div className="contact_form">
                    <p className='mt-4 title1'id='title1'>information </p>
                    <div className='main_box'>
                        <Row>
                            <Col className='col-2 p-4 pt-2'>
                              <span className='icon'>
                                <GoLocation />
                               </span>
                            </Col>
                        <Col className='col-10 p-4'>
                        <span className='icon' id='inf-title'>That School Sector 19, Mathura Road Faridabad, Haryana in india</span>
                      </Col>
                      </Row>
                    </div>
                      <div className='main_box'>
                      <Row>
                            <Col className='col-2 p-4 pt-2'>
                              <span className='icon'>
                                 <CgMail />
                               </span>
                            </Col>
                        <Col className='col-10 p-4'>
                        <span className='icon' id='inf-title'>roy122279@gmail.com</span>
                      </Col>
                      </Row>
                        
                      </div>
                      <div className='main_box'>
                      <Row>
                            <Col className='col-2 p-4 pt-2'>
                              <span className='icon'>
                                 <FaYoutube />
                               </span>
                            </Col>
                        <Col className='col-10 p-4'>
                        <span className='icon' id='inf-title'>oy122279@gmail.com</span>
                      </Col>
                      </Row>
                        
                      </div>
                    
                </div>
                </div>
                <div className="col-xl-8 col-md-12 col-sm-12 my-4">
                    <div className="contact_form"id='second'>
                        <div id="message"></div>
                        <form id="contactform" onSubmit={(e)=>form()}  name="contactform">
                            <div className="row row-fluid">
                            <p className='mt-4 title1'>contect with us </p>
                                <div className="col-lg-6 col-md-6 col-sm-6 mr-4">
                                    <input type="text" name="first_name" id="first_name" className="form-control" placeholder="First Name"/>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <input type="text" name="last_name" id="last_name" className="form-control" placeholder="Last Name"/>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <input type="email" name="email" id="email" className="form-control" placeholder="Your Email"/>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <input type="text" name="phone" id="phone" className="form-control" placeholder="Your Phone"/>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <textarea className="form-control" name="comments" id="comments" rows={6} placeholder="Give us more details.."></textarea>
                                </div>
                                <div className=" pd">
                                    <button type="submit" value="SEND" id="submit" className=" btn-light btn-radius btn-brd grd1 btn-block"> <BiSend /> Send massage </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
      </Row>
    </Container>
    
    <div>
   <Footer></Footer>
    </div>
   </>
  )
}
